import React, { Suspense, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { Image } from "react-bootstrap";
import "./style.scss";
import Slider from "react-slick";
import { Footer, Header, Modifications } from "../component/index";
import {
  CameraImg,
  Icon360,
  defaultSS,
  scissorsPng,
} from "../assets/images/index";

import Product from "../products/Product";
import { newCustomisationData, patternSubsteps } from "../data";
import { Canvas } from "@react-three/fiber";
import {
  Environment,
  OrbitControls,
  PerspectiveCamera,
} from "@react-three/drei";
import * as TWEEN from "tween.js";
import API from "../apis/api";
import PageLoader from "../component/loader";
import Inspiration from "../component/inspiration";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { getUserSession } from "../redux/userSessionSlice";
import { inspirationGetList } from "../redux/inspirationGetList";
import { getProductStep } from "../redux/productSteps";

function Home({ config }) {
  const settings = {
    vertical: true,
    dots: false,
    infinite: false,
    verticalSwiping: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    arrows: true,
    // centerMode: false,
    // swipeToSlide: true,
    prevArrow: <BiChevronLeft />,
    nextArrow: <BiChevronRight />,
  };

  const canvas = useRef(null);
  const camera = useRef(null);
  const sliderRef = useRef(null);
  const cameraRef = useRef(null);
  const [isClass, setClass] = useState("");
  const [customisationData, setCustomisationData] =
    useState(newCustomisationData); //staticCustomisationData
  const [maxZoomDistance, setMaxZoomDistance] = useState();
  const [minZoomDistance, setMinZoomDistance] = useState();
  const [stepIndex, setStepIndex] = useState(1);
  const [screenshot, setScreenshot] = useState(defaultSS);
  const [selectedColor, setselectedColor] = useState("");
  const [selectModelScreen, setselectModelScreen] = useState();
  const [productVariant] = useState([]);
  const [defaultProductData, setdefaultProductData] = useState();
  const [loadedFromInspiration, setloadedFromInspiration] = useState();
  const [model, setmodel] = useState();
  const [model_id, setmodel_id] = useState();
  const [isAlreadySaved, setisAlreadySaved] = useState();
  const [shortcode, setshortcode] = useState("");
  const [isSaving, setisSaving] = useState();
  const [productDetails, setproductDetails] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [inspirationsModelShow, setinspirationsModelShow] = useState(false);
  const [modificationsModelShow, setmodificationsModelShow] = useState(false);
  const [mainStepIndex, setMainStepIndex] = useState(); // 0
  // const [subSteps, setsubSteps] = useState(newSteps[0].subSteps);
  const [subSteps, setsubSteps] = useState();
  const [activeSubStepIndex, setactiveSubStepIndex] = useState(0);
  const [activeCamStepIndex, setactiveCamStepIndex] = useState(0);
  const [activeSubStepPatternIndex, setactiveSubStepPatternIndex] = useState(
    patternSubsteps[0]
  );
  const [collectionData, setcollectionData] = useState();
  const [selectedPattern, setselectedPattern] = useState("");
  const [cursorin, setcursorin] = useState(false);
  const [logos, setlogos] = useState("");
  const [swatchcolor, setswatchcolor] = useState("");
  const [position, setposition] = useState({ x: 0, y: 0, z: 0 });
  const [modelLoaded, setModelLoaded] = useState(false);
  const [screenshotCompleted, setScreenshotCompleted] = useState(false);
  const [bodyScroll, setBodyScroll] = useState(false);
  const [logoShow, setLogoShow] = useState(
    config.default.name === "alteryx" ? false : true
  );

  const bodyScrollStartStop = (value) => {
    setBodyScroll(value);
  };
  const dispatch = useDispatch();
  const createuser = useSelector(
    (state) => state.users?.users?.data?.session_id
  );
  const isloader = useSelector((state) => state.loader);
  const productSteps = useSelector(
    (state) => state.productSteps.stepsData?.data?.steps
  );
  // New Functionalities=
  function selectColor(params) {
    setselectedColor(params);
  }
  function selectPattern(params) {
    setselectedPattern(params);
  }
  useEffect(() => {
    // check if session is available
    if (localStorage.getItem("UserSession")) {
      //  if 0 then default first product othervise first from list
      setProductOnCanvas();
    } else {
      dispatch(getUserSession());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createuser]);

  // ? touch scroll working and stop reload on swipe
  useEffect(() => {
    const handleTouchMove = (e) => {
      if (e.touches.length === 1) {
        if (bodyScroll === false) {
          e.preventDefault();
        }
      }
    };

    // Add event listeners when the component using this hook mounts
    document.addEventListener("touchmove", handleTouchMove, { passive: false });
    // Remove event listeners when the component unmounts
    return () => {
      document.removeEventListener("touchmove", handleTouchMove);
    };
  }, [bodyScroll]);

  useEffect(() => {
    if (model) {
      dispatch(inspirationGetList(model));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  async function setProductOnCanvas() {
    try {
      const url = window.location.href;
      const productUrl = url.substring(url.lastIndexOf("/") + 1);
      setisLoading(true);

      if (productUrl === "") {
        await fetchProductList();
        if (!loadedFromInspiration) {
          setmodel(productVariant[0]?.model);
          setCustomisationData(productVariant[0]?.customizationData);
          setisLoading(false);
        }
      } else if (localStorage.getItem("UserSession") !== "") {
        await fetchProductList();
        setselectModelScreen(true);
        await fetchVariantByShortcode(productUrl);
      } else {
        setselectModelScreen(true);
        dispatch(getUserSession());
        await setProductOnCanvas(); // Recursive call, make sure to use "await"
      }
    } catch (error) {
      console.error("Error in setProductOnCanvas:", error);
    } finally {
      setisLoading(false);
    }
  }

  async function fetchProductList() {
    const backend = new API();
    const res = await backend.fetch(API.productList(), { method: "GET" });

    if (res.status === 401) {
      dispatch(getUserSession());
    }
    bodyScrollStartStop(true);
    setdefaultProductData(res.data);
    setisLoading(false);
  }

  async function fetchVariantByShortcode(productUrl) {
    const backend = new API();
    const res = await backend.fetch(API.variantByShortcode(productUrl), {
      method: "GET",
    });

    setmodel(res.data?.model);
    setmodel_id(res.data?.model_id);
    setCustomisationData(res.data?.customizationData);
    setisLoading(false);
  }

  async function variantGet(id) {
    setselectModelScreen(true);
    setisLoading(true);

    const backend2 = new API();
    backend2
      .fetch(API.startingDesign(), {
        method: "GET",
      })
      .then((res) => {
        if (res.status === 401) {
          getUserSession();
        }
        let data = [];
        if (res.data) {
          // eslint-disable-next-line array-callback-return
          res.data.map((val, index) => {
            if (val?.status === 1 && val?.product_variant?.model_id === id) {
              data.push(val);
            }
          });
        }
        if (data.length) {
          setmodel(data[0]?.product_variant?.model);
          setmodel_id(data[0]?.product_variant?.model_id);
          setCustomisationData(data[0]?.product_variant?.customizationData);
          setisLoading(false);
        } else {
          const backend = new API();
          backend
            .fetch(API.variantGet(id), {
              method: "GET",
            })
            .then((res) => {
              if (res.status === 401) {
                getUserSession();
              }

              setmodel(res?.data?.model);
              setmodel_id(id);
              setCustomisationData(newCustomisationData);
              setisLoading(false);
            });
        }
      });
    return;
  }

  // function to save design to DB on server
  async function onSave() {
    setisAlreadySaved(false);
    const backend = new API();
    const api =
      isAlreadySaved === true ? API.variantEdit(shortcode) : API.variantSave();
    camera.current.maxDistance = 0.1;
    camera.current.enableRotate = false;
    await takeScreenshot();
    const data = {
      product_url: shortcode !== null ? shortcode : randomShortcode(6),
      product_id: model_id,
      image: screenshot,
      customization_data: JSON.stringify(customisationData),
      user_session_id: localStorage.getItem("UserSession"),
    };
    // return;
    setisSaving(true);
    try {
      const res = await backend.fetch(api, {
        method: "POST",
        body: JSON.stringify(data),
      });
      setshortcode(res.data.shortcode);
      setisAlreadySaved(true);

      camera.current.maxDistance = 0.2;
      camera.current.enableRotate = true;

      setisSaving(false);
      setisAlreadySaved(true);
      return res.data.shortcode;
    } catch (error) {
      console.log("Some error occured During Saving!");
      camera.current.maxDistance = 0.2;
      camera.current.enableRotate = true;
      setisSaving(false);
      setisAlreadySaved(false);
      throw error;
    }
  }

  // function to get customization steps from Server
  useEffect(() => {
    getProductSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  async function getProductSteps() {
    setisLoading(true);

    try {
      if (defaultProductData) {
        for (const item of defaultProductData) {
          if (model === item.name) {
            setproductDetails(item);
            dispatch(getProductStep(item.id));
            setMainStepIndex(0);
            setisLoading(false);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching product steps:", error);
      setisLoading(false);
    }
  }

  const handleMouseWheel = (e) => {
    if (sliderRef.current) {
      if (e.deltaY > 0) {
        // Scroll down, go to the next slide
        sliderRef.current.slickNext();
      } else {
        // Scroll up, go to the previous slide
        sliderRef.current.slickPrev();
      }
    }
  };

  useEffect(() => {
    if (productSteps) {
      setsubSteps(productSteps[mainStepIndex]?.sub_steps);
      setactiveSubStepIndex(0);
    }
  }, [mainStepIndex, productSteps]);

  useEffect(() => {
    if (productSteps && subSteps) {
      if (mainStepIndex !== 1 && mainStepIndex !== 2 && mainStepIndex !== 0) {
        if (
          subSteps[activeSubStepIndex]?.collection_data &&
          subSteps[activeSubStepIndex]?.collection_data.length > 0
        ) {
          setcollectionData(subSteps[activeSubStepIndex]?.collection_data);
        }
      } else {
        setcollectionData(subSteps[0]?.collection_data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSubStepIndex, subSteps, productSteps]);

  // function to generate random ShortCode
  function randomShortcode(length) {
    let text = "";
    let possible = "abcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < length; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }
  function customize(params) {
    setshortcode("");
    setisSaving(false);
    setisAlreadySaved(false);
    let newData;
    switch (mainStepIndex) {
      case 0:
        newData = { ...customisationData, leather: params };
        setCustomisationData(newData);
        break;
      case 1:
        newData = { ...customisationData, pattern: params };
        setCustomisationData(newData);
        break;
      case 2:
        newData = { ...customisationData, thread: params };
        setCustomisationData(newData);
        break;
      case 3:
        newData = { ...customisationData, logo: params };
        setCustomisationData(newData);
        break;
      case 4:
        newData = { ...customisationData, inscription: params };
        setCustomisationData(newData);
        break;

      default:
        break;
    }
  }

  function setColor(params) {
    let mainStepData = {
      ...customisationData[productSteps[mainStepIndex].step_code],
    };
    let subStepData = {
      ...mainStepData[
        productSteps[mainStepIndex]?.sub_steps[activeSubStepIndex].step_code
      ],
    };
    subStepData = { ...subStepData, color: params };
    mainStepData[
      productSteps[mainStepIndex]?.sub_steps[activeSubStepIndex].step_code
    ] = subStepData;
    let newData = { ...mainStepData };
    customize(newData);
  }

  function setThreadColor(params) {
    let mainStepData = customisationData[productSteps[mainStepIndex].step_code];
    mainStepData = { ...mainStepData, color: params };
    let newData = mainStepData;
    customize(newData);
  }
  function setPattern(params) {
    let mainStepData = customisationData[productSteps[mainStepIndex].step_code];
    let subStepData = mainStepData[activeSubStepPatternIndex?.stepCode];
    mainStepData[activeSubStepPatternIndex?.stepCode] = {
      ...subStepData,
      pattern: params,
    };
    let newData = mainStepData;
    customize(newData);
  }

  const changeColor = () => {
    setColor(selectedColor);
  };
  const changePattern = (params) => {
    setPattern(selectedPattern);
  };

  useEffect(() => {
    const animate = () => {
      TWEEN.update();
      requestAnimationFrame(animate);
    };

    animate();
  }, []);
  function takeScreenshot() {
    new TWEEN.Tween(camera.current)
      .to({ polarAngle: Math.PI / 2 }, 1000)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onUpdate(() => {
        camera.current.setAzimuthalAngle(0);
        camera.current.setPolarAngle(Math.PI / 2);
        setposition({ x: 0, y: 0, z: 0 });
        setScreenshotCompleted(false);
      })
      .onComplete(() => {
        const screenshot = canvas.current.toDataURL();
        setScreenshotCompleted(true);
        setScreenshot(screenshot);
      })
      .start();
  }
  useEffect(() => {
    if (window.innerWidth < 400) {
      // Adjust the value as needed for screens narrower than 400px
      setMaxZoomDistance(0.28);
      setMinZoomDistance(0.4);
    } else if (window.innerWidth < 576) {
      // Adjust the value as needed for screens narrower than 576px
      setMaxZoomDistance(0.26);
      setMinZoomDistance(0.4);
    } else if (window.innerWidth < 768) {
      // Adjust the value as needed for screens narrower than 768px
      setMaxZoomDistance(0.25);
      setMinZoomDistance(0.35);
    } else if (window.innerWidth < 992) {
      // Adjust the value as needed for screens narrower than 992px
      setMaxZoomDistance(0.22);
      setMinZoomDistance(0.3);
    } else if (window.innerWidth < 1200) {
      // Adjust the value as needed for screens narrower than 1200px
      setMaxZoomDistance(0.22);
      setMinZoomDistance(0.35);
    } else if (window.innerWidth < 1400) {
      // Adjust the value as needed for screens narrower than 1200px
      setMaxZoomDistance(0.2);
      setMinZoomDistance(0.33);
    } else {
      // Default value for wider screens
      setMaxZoomDistance(0.2);
      setMinZoomDistance(0.3);
    }
  }, []);

  function rotateCamera(activeIndex = 0) {
    if (camera.current) {
      switch (activeIndex) {
        case 0:
          camera.current.setAzimuthalAngle(0);
          camera.current.setPolarAngle(Math.PI / 2);
          break;
        case 1:
          camera.current.setAzimuthalAngle(Math.PI / 4);
          camera.current.setPolarAngle(Math.PI / 2);
          break;
        case 2:
          camera.current.setAzimuthalAngle((3 * Math.PI) / 4);
          camera.current.setPolarAngle(Math.PI / 2);
          break;
        case 3:
          camera.current.setAzimuthalAngle(Math.PI);
          camera.current.setPolarAngle(Math.PI / 2);
          break;
        case 4:
          camera.current.setAzimuthalAngle((5 * Math.PI) / 4);
          camera.current.setPolarAngle(Math.PI / 2);
          break;
        case 5:
          camera.current.setAzimuthalAngle(Math.PI / -4);
          camera.current.setPolarAngle(Math.PI / 2);
          break;

        default:
          break;
      }
    }
  }
  // default select in halfwallet on startup
  useEffect(() => {
    const url = window.location.href;
    const productUrl = url.substring(url.lastIndexOf("/") + 1);
    if (defaultProductData && !productUrl) {
      variantGet(config?.default?.modelId);
      // variantGet(config);
      bodyScrollStartStop(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultProductData]);

  useEffect(() => {
    if (camera.current) {
      if (model_id === 4) {
        if (mainStepIndex === 4) {
          camera.current.setAzimuthalAngle(3.14);
          camera.current.setPolarAngle(Math.PI / 2);
          camera.current.enableRotate = false;
          camera.current.enableZoom = false;
          if (!isMobile) {
            setTimeout(() => setposition({ x: 0, y: 0, z: -0.04 }), 300);
          }
        } else {
          camera.current.enableRotate = true;
          camera.current.enableZoom = true;
          if (!isMobile) {
            setTimeout(() => setposition({ x: 0, y: 0, z: 0.0 }), 300);
          }
        }
      } else {
        if (
          activeSubStepIndex === 0 ||
          activeSubStepIndex === 1 ||
          activeSubStepIndex === 2
        ) {
          camera.current.setAzimuthalAngle(0);
          camera.current.setPolarAngle(Math.PI / 2);
        }
        if (
          activeSubStepIndex === 3 ||
          activeSubStepIndex === 4 ||
          activeSubStepIndex === 5
        ) {
          camera.current.setAzimuthalAngle(3.14);
          camera.current.setPolarAngle(Math.PI / 2);
        }
        if (activeSubStepIndex === 0 && mainStepIndex === 4) {
          camera.current.setAzimuthalAngle(3.14);
          camera.current.setPolarAngle(Math.PI / 2);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSubStepIndex, mainStepIndex]);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const handleMouseMove = (event) => {
    setCursorPosition({ x: event.clientX, y: event.clientY });
  };

  const handleDotArrowLeft = () => {
    let newActiveIndex = activeCamStepIndex > 0 ? activeCamStepIndex - 1 : 5;
    setactiveCamStepIndex(newActiveIndex);
    rotateCamera(newActiveIndex);
  };
  const handleDotArrowRight = () => {
    let newActiveIndex = activeCamStepIndex < 5 ? activeCamStepIndex + 1 : 0;
    setactiveCamStepIndex(newActiveIndex);
    rotateCamera(newActiveIndex);
  };

  // Calculate dynamic badge color based on cursor's X position
  const badgeColor = mainStepIndex === 0 ? swatchcolor : "";
  const badgeimg = mainStepIndex === 1 ? logos : "";

  return (
    <>
      {mainStepIndex !== 2 &&
        mainStepIndex !== 3 &&
        mainStepIndex !== 4 &&
        (badgeColor || badgeimg) && (
          <div className="position-relative d-none d-lg-block">
            {cursorin === true && (
              <div
                className="position-absolute text-white rounded-circle cursor-arrow"
                style={{
                  top: cursorPosition.y,
                  left: cursorPosition.x,
                  backgroundColor: badgeColor, // Use the calculated badge color
                  backgroundImage: `url(${badgeimg})`,
                }}
              ></div>
            )}
          </div>
        )}
      {isLoading && isloader && <PageLoader />}
      {!selectModelScreen ? (
        <div className="flex-container">
          <ul className="image-gallery">
            {defaultProductData?.map((item, index) => {
              return (
                <li key={index}>
                  <Image
                    src={item.thumbnail}
                    onClick={() => {
                      variantGet(index + 1);
                      bodyScrollStartStop(false);
                    }}
                    fluid
                    draggable="false"
                  />
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        <div>
          <Header
            takeScreenshot={takeScreenshot}
            {...{
              customisationData,
              setCustomisationData,
              screenshot,
              onSave,
              shortcode,
              isSaving,
              productDetails,
              model,
              setloadedFromInspiration,
              isAlreadySaved,
              setisAlreadySaved,
              rotateCamera,
              screenshotCompleted,
              model_id,
              mainStepIndex,
              bodyScrollStartStop,
              config,
            }}
          />
          <div
            className={
              isClass +
              " " +
              "body-content d-lg-flex justify-content-lg-between justify-content-lg-center align-items-center w-full"
            }
          >
            <div className="left-color d-none d-lg-block position-fixed">
              {(mainStepIndex === 2 || mainStepIndex === 0) && (
                <div onWheel={handleMouseWheel}>
                  <Slider ref={sliderRef} {...settings}>
                    {collectionData &&
                      collectionData?.map((value, index) => {
                        return (
                          <div key={index}>
                            <button
                              key={value.id}
                              className={
                                (
                                  mainStepIndex === 0
                                    ? value.inspiration_color === selectedColor
                                    : customisationData?.[
                                        productSteps[mainStepIndex]?.step_code
                                      ]?.color === value?.inspiration_color
                                )
                                  ? "color-round rounded-circle position-relative p-0 animate active"
                                  : "color-round rounded-circle position-relative p-0 animate"
                              }
                              style={{
                                backgroundColor: value?.color,
                              }}
                              onClick={
                                mainStepIndex === 0
                                  ? () => {
                                      if (
                                        value?.inspiration_color ===
                                        selectedColor
                                      ) {
                                        selectColor("");
                                        setswatchcolor("");
                                      } else {
                                        selectColor(value?.inspiration_color);
                                        setswatchcolor(value?.color);
                                      }
                                    }
                                  : () =>
                                      setThreadColor(value?.inspiration_color)
                              }
                            >
                              <span className="color-name text-black text-sm font-semibold">
                                {value?.name}
                              </span>
                            </button>
                          </div>
                        );
                      })}
                  </Slider>
                </div>
              )}
              {mainStepIndex === 1 && (
                <div
                  className={`left-pattern d-none d-lg-flex me-6 flex-lg-column py-6 rounded-start-3`}
                >
                  <span className="text-black patterns-text">
                    Patterns coming soon
                  </span>
                </div>
              )}
            </div>
            <div className="center-image text-center mx-auto h-lg-full position-relative">
              <div className={`icon-360-button ${!modelLoaded && "d-none"}`}>
                <div className="p-3 rounded-3 icon-360 hstack justify-content-center gap-2 position-absolute top-1/2 start-1/2 translate-middle overlap-10">
                  <Image className="w-6 h-6" src={Icon360} />
                  <p className="text-dark">Drag to view from any angle</p>
                </div>
              </div>
              <Canvas
                onMouseMove={handleMouseMove}
                onMouseEnter={() => {
                  setcursorin(true);
                }}
                onMouseLeave={() => {
                  setcursorin(false);
                }}
                ref={canvas}
                camera={{
                  fov: 50,
                  position: [0, 0, 0.18],
                  near: 0.001,
                  far: 1000,
                }}
                gl={{ preserveDrawingBuffer: true }}
                onPointerDown={() => {
                  setModelLoaded(false);
                }}
                onPointerMissed={() => {
                  selectColor("");
                  setswatchcolor("");
                }}
              >
                <PerspectiveCamera
                  makeDefault
                  position={[0, 0, 0.18]}
                  ref={cameraRef}
                />
                <OrbitControls
                  ref={camera}
                  enableZoom={true}
                  enableRotate={true}
                  enablePan={false}
                  maxDistance={minZoomDistance} //0.2 how much can you get far
                  minDistance={maxZoomDistance} //0.1
                  zoomspeed={0.5}
                  camera={cameraRef.current}
                />
                <Suspense fallback={null}>
                  <Environment
                    files={[
                      "EnvironmentHDRi/cube/pz2.png", //left Lighting
                      "EnvironmentHDRi/cube/pz2.png", // right Lighting
                      "EnvironmentHDRi/cube/py.png", // top Lighting
                      "EnvironmentHDRi/cube/ny.png", // Bottom Lighting
                      "EnvironmentHDRi/cube/pp.png", // front Lighting
                      "EnvironmentHDRi/cube/pp.png", //back Lighting
                    ]}
                    path={"/"}
                    background={false}
                  />
                  <Product
                    position={position}
                    {...{
                      model,
                      customisationData,
                      setStepIndex,
                      setMainStepIndex,
                      setactiveSubStepIndex,
                      setactiveSubStepPatternIndex,
                      activeSubStepPatternIndex,
                      selectedColor,
                      changeColor,
                      selectedPattern,
                      mainStepIndex,
                      changePattern,
                      activeSubStepIndex,
                      position,
                      setModelLoaded,
                      config,
                      logoShow,
                    }}
                  />
                </Suspense>
              </Canvas>
              <div
                className={`canvas-part py-2 bg-bg-vista-white ${config.default.dotClasses}`}
              >
                {model_id !== 4 && model_id !== 5 ? (
                  <div className="canvas-part-dot">
                    {subSteps &&
                      mainStepIndex === 0 &&
                      subSteps?.map((val, ind) => {
                        return (
                          <span
                            key={ind}
                            onClick={() =>
                              setactiveSubStepIndex(val.step_id - 1)
                            }
                            className={
                              activeSubStepIndex === val.step_id - 1
                                ? "active"
                                : undefined
                            }
                          ></span>
                        );
                      })}
                    {subSteps &&
                      mainStepIndex === 1 &&
                      model_id !== 3 &&
                      model_id !== 2 &&
                      model_id !== 1 &&
                      patternSubsteps?.map((val, ind) => {
                        return (
                          <span
                            key={ind}
                            onClick={() => {
                              setactiveSubStepPatternIndex(val);
                              setactiveSubStepIndex(val?.subStepIndex);
                            }}
                            className={
                              activeSubStepPatternIndex?.subStepId === ind
                                ? "active"
                                : undefined
                            }
                          ></span>
                        );
                      })}
                  </div>
                ) : (
                  <div
                    className={`canvas-part-dot ${
                      mainStepIndex === 4 ? "inscription-active" : undefined
                    }`}
                  >
                    <BiChevronLeft
                      onClick={() => handleDotArrowLeft()}
                      className="canvas-part-left-arrow cursor-pointer"
                    />
                    {Array.from({ length: 6 }).map((val, ind) => {
                      return (
                        <span
                          key={ind}
                          onClick={() => {
                            setactiveCamStepIndex(ind);
                            rotateCamera(ind);
                          }}
                          className={
                            activeCamStepIndex === ind ? "active" : undefined
                          }
                        ></span>
                      );
                    })}
                    <BiChevronRight
                      onClick={() => handleDotArrowRight()}
                      className="canvas-part-right-arrow cursor-pointer"
                    />
                  </div>
                )}
              </div>
            </div>

            <div
              className={`right-pattern d-none d-lg-flex gap-6 bg-linen flex-lg-column py-6 rounded-start-3 px-6`}
            >
              <>
                <Inspiration
                  key={1}
                  placement={"bottom"}
                  show={inspirationsModelShow}
                  setShow={setinspirationsModelShow}
                  {...{
                    customisationData,
                    setCustomisationData,
                    setloadedFromInspiration,
                    bodyScrollStartStop,
                  }}
                />
                {model_id === 4 && (
                  <Modifications
                    key={0}
                    show={modificationsModelShow}
                    setShow={setmodificationsModelShow}
                    bodyScrollStartStop={bodyScrollStartStop}
                    placement={"bottom"}
                    name=<Image
                      src={scissorsPng}
                      draggable="false"
                      style={{ width: "26px" }}
                    />
                    customisationData={customisationData}
                    setCustomisationData={setCustomisationData}
                    title={"Modifications"}
                  />
                )}
                <div
                  className="d-flex align-items-center gap-2 right-pattern-text cursor-pointer text-dark"
                  onClick={(e) => {
                    if (mainStepIndex !== 4) {
                      if (model_id === 4 || model_id === 5) {
                        const newActiveIndex =
                          activeCamStepIndex < 5 ? activeCamStepIndex + 1 : 0;
                        setactiveCamStepIndex(newActiveIndex);

                        rotateCamera(newActiveIndex);
                      } else {
                        if (
                          camera.current.getAzimuthalAngle() > -Math.PI / 2 &&
                          camera.current.getAzimuthalAngle() < Math.PI / 2
                        ) {
                          camera.current.setAzimuthalAngle(3.14);
                          camera.current.setPolarAngle(Math.PI / 2);
                        } else {
                          camera.current.setAzimuthalAngle(0);
                          camera.current.setPolarAngle(Math.PI / 2);
                        }
                      }
                    }
                  }}
                >
                  <Image src={CameraImg} draggable="false" />
                  <span className="font-semibold">Rotate</span>
                </div>
              </>
            </div>
          </div>
          <Footer
            handleClass={(e) => {
              setClass(e);
            }}
            {...{
              stepIndex,
              setStepIndex,
              customize,
              customisationData,
              setCustomisationData,
              setColor,
              setPattern,
              productDetails,
              model,
              setloadedFromInspiration,
              collectionData,
              screenshot,
              takeScreenshot,
              mainStepIndex,
              setMainStepIndex,
              isSaving,
              isAlreadySaved,
              setisAlreadySaved,
              onSave,
              shortcode,
              selectedColor,
              selectColor,
              setswatchcolor,
              setThreadColor,
              selectedPattern,
              selectPattern,
              setlogos,
              rotateCamera,
              screenshotCompleted,
              setactiveCamStepIndex,
              model_id,
              bodyScrollStartStop,
              config,
              setLogoShow,
              logoShow,
            }}
          />
        </div>
      )}
    </>
  );
}

export default Home;
