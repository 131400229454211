import React from "react";
import { Button, Image, Modal } from "react-bootstrap";
import { GrFormClose } from "react-icons/gr";
import { ModalLogo } from "../../assets/images";

function LogoModal({ show, setShow }) {
  const handleClose = () => {
    setShow(!show);
  };
  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="ShareOrSaveYourDesign"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="border-0 pe-0 bg-rose-white justify-content-start">
          <Button
            variant="transparent"
            className="shadow-none border-0 p-0"
            onClick={handleClose}
          >
            <GrFormClose className="text-2xl" />
            <span className="text-black">Close</span>
          </Button>
        </Modal.Header>
        <Modal.Body className="pt-0 px-sm-20 bg-rose-white welcome-modal">
          <div className="text-center mb-10">
            <Image src={ModalLogo} className="w-56" draggable="false" />
          </div>
          <p className="mb-4 text-black">
            Welcome to the beta version of our new and improved wallet
            customizer, Woolly Lab! We’re thrilled to invite you to play
            designer with our most popular product.
          </p>
          <p className="mb-4 text-black">
            We’ve rebuilt our customizer from scratch and made lots of changes
            to the interface. You might notice the wallet rendering is now 3D,
            so you can see a live preview—up close and from any angle—while you
            customize. Soon, you’ll be able to apply patterns to wallet pieces
            and customize other products in our line.
          </p>
          <p className="mb-4 text-black">
            If you have feedback to share, we’d love to hear from you at
            <a href="mailto:hello@woollymade.com?subject=Woolly Lab Feedback">
              hello@woollymade.com
            </a>
            .
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default LogoModal;
