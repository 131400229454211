import React, { useState } from "react";
import {
  Container,
  Navbar,
  Nav,
  Image,
  Button,
  Dropdown,
} from "react-bootstrap";
import {
  InfoIcon,
  toggle,
  scissorsPng,
  LightWithoutCircle,
} from "../../assets/images/index";
import { FiSend } from "react-icons/fi";
import ProductInfo from "../productInfo";
import { AboutModal, DoneModal, ShareOrSaveYourDesign } from "../modal";
import "./style.scss";
import Inspiration from "../inspiration";
import Modifications from "../modifications";
import LogoModal from "../modal/logoModal";
import { FaHeart } from "react-icons/fa";

function Header({
  customisationData,
  setCustomisationData,
  screenshot,
  takeScreenshot,
  onSave,
  shortcode,
  isSaving,
  productDetails,
  model,
  setloadedFromInspiration,
  isAlreadySaved,
  setisAlreadySaved,
  rotateCamera,
  screenshotCompleted,
  model_id,
  mainStepIndex,
  bodyScrollStartStop,
  config,
}) {
  const [aboutModalShow, setAboutModalShow] = useState(false);
  const [shareModelShow, setShareModelShow] = useState(false);
  const [doneModelShow, setDoneModelShow] = useState(false);
  const [productInfoModelShow, setproductInfoModelShow] = useState(false);
  const [inspirationsModelShow, setinspirationsModelShow] = useState(false);
  const [modificationsModelShow, setmodificationsModelShow] = useState(false);
  const [logoModelShow, setlogoModelShow] = useState(false);

  return (
    <>
      <div className="position-fixed w-full overlap-10">
        <Navbar
          className={`Navbar py-0 ${config?.default?.headerColor}`}
          collapseOnSelect
          expand="md"
          variant="light"
        >
          <Container fluid className="position-relative">
            <Navbar.Brand className="logo">
              <Image
                className={`img ${
                  config.default.name !== "alteryx" && "cursor-pointer"
                } `}
                src={config?.default?.image}
                onClick={() => {
                  config.default.name !== "alteryx" && setlogoModelShow(true);
                }}
                draggable="false"
              />
            </Navbar.Brand>
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="d-none d-md-block"
            >
              {/* <Nav className="me-auto">
                <Nav.Link className="bg-transparent"> </Nav.Link>
              </Nav> */}
              <Nav className="topnav-centered">
                <Nav.Link
                  className={`bg-transparent p-0 text-avenir-heavy cursor-pointer`}
                  onClick={() => {
                    // config.default.name !== "alteryx" && (
                    <>{(setAboutModalShow(true), bodyScrollStartStop(true))}</>;
                    // );
                  }}
                  draggable="false"
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: config?.default?.headerContent,
                    }}
                  ></div>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
            <div className="d-block navbar-menu">
              <Dropdown className="d-block py-5">
                <Dropdown.Toggle
                  id="dropdown-custom-components"
                  className="border-0 btn-primary dropdown-toggle bg-transparent shadow-none p-0 text-start d-md-flex justify-content-end"
                >
                  <Image src={toggle} draggable="false" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="droupdown text-start">
                  <Dropdown.Item
                    className="pb-md-0 px-4 font-regular"
                    onClick={() => {
                      takeScreenshot();
                      setShareModelShow(true);
                      bodyScrollStartStop(true);
                    }}
                    draggable="false"
                  >
                    <span className="text-black font-regular text-base d-flex align-items-center">
                      <FiSend className="w-5 h-5 me-2" />
                      <p className="text-black text-sm font-semibold">
                        Share Design
                      </p>
                    </span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="pb-md-0 px-4 font-regular"
                    onClick={() => {
                      setproductInfoModelShow(true);
                      bodyScrollStartStop(true);
                    }}
                    draggable="false"
                  >
                    <span className="text-black font-regular text-base d-flex align-items-center">
                      <Image
                        src={InfoIcon}
                        className="w-5 me-2 pe-0"
                        draggable="false"
                      />
                      <p className="text-sm lh-normal font-semibold mt-1">
                        Product Details
                      </p>
                    </span>
                  </Dropdown.Item>
                  {config.default.name !== "alteryx" && (
                    <Dropdown.Item
                      className="pb-md-0 px-4 font-regular d-lg-none d-block"
                      onClick={() => {
                        setmodificationsModelShow(true);
                        bodyScrollStartStop(true);
                      }}
                      draggable="false"
                    >
                      <span className="text-black font-semibold text-base d-flex align-items-center">
                        <Image
                          src={scissorsPng}
                          className="modifications h-5 me-md-2 me-1"
                          draggable="false"
                        />
                        <p className="text-sm lh-normal">Modifications</p>
                      </span>
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    className="d-lg-none text-dark font-semibold"
                    onClick={() => {
                      setinspirationsModelShow(true);
                      bodyScrollStartStop(true);
                    }}
                    draggable="false"
                  >
                    <span className="text-black font-semibold text-base d-flex align-items-center">
                      <Image
                        className="me-md-2 me-1 w-md-5"
                        src={LightWithoutCircle}
                        draggable="false"
                      />
                      <p className="text-sm lh-normal">Inspiration </p>
                    </span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="d-lg-none pb-md-0 pt-md-0 d-flex gap-2 text-dark font-semibold"
                    onClick={() => {
                      setAboutModalShow(true);
                      bodyScrollStartStop(true);
                    }}
                    draggable="false"
                  >
                    <span className="text-black font-semibold text-base d-flex align-items-center">
                      <FaHeart
                        className="text-lg me-2"
                        style={{ color: "#e15241" }}
                      />
                      <p className="text-sm lh-normal">About Us</p>
                    </span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <ProductInfo
                placement={"bottom"}
                show={productInfoModelShow}
                setShow={setproductInfoModelShow}
                bodyScrollStartStop={bodyScrollStartStop}
                {...{
                  customisationData,
                  setCustomisationData,
                  productDetails,
                  config,
                }}
                fromHeader={true}
              />
              <Inspiration
                key={1}
                placement={"bottom"}
                show={inspirationsModelShow}
                setShow={setinspirationsModelShow}
                bodyScrollStartStop={bodyScrollStartStop}
                {...{
                  customisationData,
                  setCustomisationData,
                  model,
                  setloadedFromInspiration,
                }}
                fromHeader={true}
              />
            </div>
            <Navbar.Brand className="d-md-none">
              {mainStepIndex !== 4 && (
                <Button
                  className="btn"
                  variant="light"
                  onClick={() => {
                    takeScreenshot();
                    setDoneModelShow(true);
                    bodyScrollStartStop(true);
                  }}
                >
                  Done
                </Button>
              )}
            </Navbar.Brand>
          </Container>
        </Navbar>
      </div>
      {/* plcace components / models like sproductInfo/Inspiration here */}
      <ProductInfo
        placement={"bottom"}
        show={productInfoModelShow}
        setShow={setproductInfoModelShow}
        bodyScrollStartStop={bodyScrollStartStop}
        {...{ customisationData, setCustomisationData, productDetails }}
        fromHeader={true}
        config={config}
      />
      {/* plcace components / models like share/Done/About here */}
      <AboutModal
        show={aboutModalShow}
        setShow={setAboutModalShow}
        bodyScrollStartStop={bodyScrollStartStop}
      />
      <ShareOrSaveYourDesign
        show={shareModelShow}
        setShow={setShareModelShow}
        bodyScrollStartStop={bodyScrollStartStop}
        {...{
          screenshot,
          shortcode,
          onSave,
          isSaving,
          productDetails,
          screenshotCompleted,
          config,
        }}
      />
      <Modifications
        placement={"bottom"}
        show={modificationsModelShow}
        setShow={setmodificationsModelShow}
        bodyScrollStartStop={bodyScrollStartStop}
        customisationData={customisationData}
        setCustomisationData={setCustomisationData}
      ></Modifications>
      {logoModelShow && (
        <LogoModal show={logoModelShow} setShow={setlogoModelShow} />
      )}
      <DoneModal
        show={doneModelShow}
        setShow={setDoneModelShow}
        bodyScrollStartStop={bodyScrollStartStop}
        {...{
          setShareModelShow,
          screenshot,
          onSave,
          isSaving,
          isAlreadySaved,
          setisAlreadySaved,
          customisationData,
          screenshotCompleted,
          model,
          shortcode,
          model_id,
          config,
        }}
      />
    </>
  );
}

export default Header;
