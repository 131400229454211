import React, { useRef, useState } from "react";
import {
  Row,
  Col,
  Image,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { HiOutlineMinus } from "react-icons/hi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { DoneModal, ShareOrSaveYourDesign } from "./modal";
import { mainStepLogos } from "../data";
import Offcanvas from "react-bootstrap/Offcanvas";
import { GrFormClose } from "react-icons/gr";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { isMobile, isTablet, useMobileOrientation } from "react-device-detect";
import { useSelector } from "react-redux";
import AlteryxLogoButtons from "./alteryxLogoButtons";
import InscriptionLogoButtons from "./inscriptionLogoButtons";

function Footer({
  handleClass,
  stepIndex,
  setStepIndex,
  customize,
  customisationData,
  setCustomisationData,
  setColor,
  onSave,
  isSaving,
  setPattern,
  productDetails,
  model,
  screenshot,
  takeScreenshot,
  collectionData,
  isAlreadySaved,
  setisAlreadySaved,
  mainStepIndex,
  setMainStepIndex,
  shortcode,
  selectedColor,
  selectColor,
  setswatchcolor,
  setThreadColor,
  rotateCamera,
  screenshotCompleted,
  setactiveCamStepIndex,
  model_id,
  bodyScrollStartStop,
  config,
  setLogoShow,
  logoShow,
}) {
  const footerSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 13,
    verticalSwiping: false,
    // swipeToSlide: true,
    slidesToScroll: 6,
    arrows: true,
    // centerMode: false,
    prevArrow: <BiChevronLeft />,
    nextArrow: <BiChevronRight />,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 12,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 11,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 10,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 8,
        },
      },
      {
        breakpoint: 510,
        settings: {
          slidesToShow: 7,
        },
      },
      {
        breakpoint: 475,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 410,
        settings: {
          slidesToShow: 5,
        },
      },
    ],
  };
  const productStepsdata = useSelector(
    (state) => state.productSteps.stepsData?.data?.steps
  );
  const textbox = useRef(null);
  const textbox2 = useRef(null);
  const [clicked, setClicked] = useState("");
  const [inscription, setInscription] = useState("");
  const [shareModelShow, setShareModelShow] = useState(false);
  const [doneModelShow, setDoneModelShow] = useState(false);
  const sliderRef = useRef(null);

  const { isLandscape } = useMobileOrientation();

  const handleClick = () => {
    clicked ? setClicked("") : setClicked("active");
    handleClass(clicked === "" ? "body-height" : "");
  };

  const getBase64FromUrl = async (url) => {
    var proxyUrl = "https://cors.stage.eugeniuses.com/";
    const data = await fetch(proxyUrl + url); // proxy used
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };
  const calulate = async (e, value) => {
    const logo = {};
    logo.width = e.target.height > 108 ? 120 : e.target.width;
    logo.height = e.target.height > 108 ? 120 : e.target.height;
    logo.name = e.target.alt;
    logo.src = await getBase64FromUrl(e.target.src);
    await customize(logo);
  };
  const clearText = () => {
    setInscription("");
    updateItems("");
  };
  const textChangeHandle = (e) => {
    if (e.target.value.length < 29) {
      setInscription(e.target.value);
      updateItems(e.target.value);
    }
  };
  const updateItems = (params) => {
    customize(params);
  };
  const close = () => {
    document.getElementById("global-backdrop2").style.display = "none";
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleMouseWheel = (e) => {
    const delta = e.deltaY || e.detail || e.wheelDelta;
    if (delta > 0) {
      sliderRef.current.slickNext();
    } else {
      sliderRef.current.slickPrev();
    }
  };

  return (
    <>
      <div className="footer-content mt-auto bg-linen position-lg-fixed bottom-0 w-full px-md-8">
        <div
          id="global-backdrop2"
          className="global-backdrop"
          style={{ display: "none" }}
          onClick={() => close()}
        ></div>
        <div
          onClick={() => handleClick()}
          className="text-center app-drawer d-none"
        >
          <Button variant="transparent shadow-none border-0" className="p-0">
            <HiOutlineMinus className="text-Vanilla" />
          </Button>
        </div>
        <Row className="py-5 py-lg-4 h-full align-items-center">
          <Col sm={3}>
            {(mainStepIndex === 4 || mainStepIndex === 3) && (
              <p>
                <Button
                  variant="transparent"
                  className="shadow-none border-0 p-0 d-lg-block d-none"
                  onClick={() => setMainStepIndex(0)}
                >
                  <GrFormClose className="text-2xl" />
                  <span className="text-black">Close</span>
                </Button>
              </p>
            )}
          </Col>
          <Col sm={6} className="text-center px-0">
            <div className="footer-all-option hstack justify-content-center gap-lg-6 gap-7">
              {productStepsdata?.map((val, ind) => {
                // for pattern
                if (config.default.name === "alteryx" && val.step_id === 2) {
                  // eslint-disable-next-line array-callback-return
                  return;
                }
                // for footer logo
                if (
                  process.env.REACT_APP_NODE_ENV === "production" &&
                  config.default.name !== "alteryx" &&
                  val.step_id === 4
                ) {
                  // eslint-disable-next-line array-callback-return
                  return;
                }
                return (
                  <div
                    key={ind}
                    // className="footer-icon "
                    className={`${
                      config?.default?.footerClasses
                    }  footer-icon ${
                      mainStepIndex === ind ? "active" : undefined
                    }`}
                    onClick={() => {
                      handleShow();
                      // setMainStepIndex(val?.stepId);
                      if (ind !== 4 && mainStepIndex === 4) {
                        rotateCamera(3);
                        setactiveCamStepIndex(3);
                      }
                      setMainStepIndex(ind);
                    }}
                  >
                    {ind === 3 && config.default.name === "alteryx"
                      ? config.default.mainStepLogo
                      : mainStepLogos[ind].logo}
                    <p
                      className={`text-avenir-medium li-none mt-n1 ${
                        val?.step_id === 3 ? "ms-n3" : undefined
                      }`}
                    >
                      {val?.step_name}
                    </p>
                  </div>
                );
              })}
            </div>
          </Col>
          <Col sm={3}>
            {mainStepIndex !== 4 && mainStepIndex !== 3 && (
              <Button
                className="font-max-bold done-btn rounded-5 px-8 py-2 d-none d-md-flex ms-auto lh-normal"
                variant="btn btn-outline-black"
                onClick={() => {
                  takeScreenshot();
                  setDoneModelShow(true);
                  bodyScrollStartStop(true);
                }}
              >
                Done
              </Button>
            )}
          </Col>
        </Row>
        <div
          className={
            clicked + " color-pattern-hide d-flex justify-content-center"
          }
        >
          {mainStepIndex === 3 && config.default.name === "alteryx" ? (
            <AlteryxLogoButtons setLogoShow={setLogoShow} logoShow={logoShow} />
          ) : (
            mainStepIndex === 3 && (
              <Row className="mb-3">
                <Col className="d-flex justify-content-center Thread-BottomPiece-body color-circle-Thread">
                  <div className="nav overflow-x gap-10 mt-auto mb-lg-14 mb-12 align-items-center">
                    {collectionData &&
                      collectionData?.map((value, index) => {
                        return (
                          value.status === 1 && (
                            <OverlayTrigger
                              key={index}
                              delay={{ hide: 100, show: 100 }}
                              overlay={(props) => (
                                <Tooltip {...props}>{value?.name}</Tooltip>
                              )}
                              placement="bottom"
                            >
                              <Image
                                className="branding mx-3  h-full"
                                src={value?.image}
                                alt={value?.name}
                                onClick={(e) => calulate(e, value)}
                                draggable="false"
                              />
                            </OverlayTrigger>
                          )
                        );
                      })}
                  </div>
                </Col>
              </Row>
            )
          )}
          {mainStepIndex === 4 && !isMobile && (
            <div className="d-block d-lg-block">
              <InscriptionLogoButtons
                clearText={clearText}
                textbox={textbox}
                inscription={inscription}
                textChangeHandle={textChangeHandle}
                textbox2={textbox2}
              />
            </div>
          )}
          {mainStepIndex === 4 && isTablet && isLandscape && (
            <div className="d-block d-lg-block">
              <InscriptionLogoButtons
                clearText={clearText}
                textbox={textbox}
                inscription={inscription}
                textChangeHandle={textChangeHandle}
                textbox2={textbox2}
              />
            </div>
          )}
        </div>
      </div>
      <DoneModal
        show={doneModelShow}
        setShow={setDoneModelShow}
        setShareModelShow={setShareModelShow}
        bodyScrollStartStop={bodyScrollStartStop}
        screenshot={screenshot}
        onSave={onSave}
        isSaving={isSaving}
        isAlreadySaved={isAlreadySaved}
        setisAlreadySaved={setisAlreadySaved}
        customisationData={customisationData}
        {...{
          screenshotCompleted,
          model,
          shortcode,
          model_id,
          config,
        }}
      />
      <ShareOrSaveYourDesign
        show={shareModelShow}
        setShow={setShareModelShow}
        screenshot={screenshot}
        isSaving={isSaving}
        onSave={onSave}
        shortcode={shortcode}
        productDetails={productDetails}
        screenshotCompleted={screenshotCompleted}
        bodyScrollStartStop={bodyScrollStartStop}
        config={config}
      />
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement={"bottom"}
        backdrop={false}
        scroll={true}
        className="color-footer-canvas bg-vista-white d-block d-lg-none"
      >
        <Offcanvas.Header className="pt-2 pb-0">
          <h6 className="w-full text-center me-n6">
            {mainStepIndex === 0
              ? "Leather"
              : mainStepIndex === 1
              ? "Pattern"
              : mainStepIndex === 2
              ? "Thread"
              : mainStepIndex === 4
              ? "Inscription"
              : ""}
          </h6>
          <Button
            variant="transparent"
            className="shadow-none border-0 p-0 justify-content-end "
            onClick={handleClose}
          >
            <GrFormClose
              className="text-2xl"
              onClick={() => {
                selectColor("");
                setswatchcolor("");
                if (mainStepIndex === 4) {
                  setMainStepIndex(0);
                  rotateCamera(3);
                  setactiveCamStepIndex(3);
                }
              }}
            />
          </Button>
        </Offcanvas.Header>
        <Offcanvas.Body className="pt-2 pb-2 px-4">
          <div className="left-color d-lg-none px-5">
            {(mainStepIndex === 2 || mainStepIndex === 0) && (
              <div onWheel={handleMouseWheel}>
                <Slider {...footerSettings} ref={sliderRef}>
                  {collectionData &&
                    collectionData?.map((value, index) => {
                      return (
                        <div key={index}>
                          <button
                            key={value.id}
                            className={
                              (
                                mainStepIndex === 0
                                  ? value.inspiration_color === selectedColor
                                  : customisationData?.[
                                      productStepsdata[mainStepIndex]?.step_code
                                    ]?.color === value?.color
                              )
                                ? "color-round rounded-circle position-relative p-0 animate active"
                                : "color-round rounded-circle position-relative p-0 animate"
                            }
                            style={{
                              backgroundColor: value?.inspiration_color,
                            }}
                            onClick={
                              mainStepIndex === 0
                                ? () => {
                                    selectColor(value?.inspiration_color);
                                    setswatchcolor(value?.color);
                                  }
                                : () => setThreadColor(value?.color)
                            }
                          >
                            <span className="color-name text-black text-sm font-semibold">
                              {value?.name}
                            </span>
                          </button>
                        </div>
                      );
                    })}
                </Slider>
              </div>
            )}
            {mainStepIndex === 3 && config.default.name === "alteryx" ? (
              <AlteryxLogoButtons
                setLogoShow={setLogoShow}
                logoShow={logoShow}
              />
            ) : (
              mainStepIndex === 3 && (
                <Row className="mb-3">
                  <Col className="d-flex justify-content-center Thread-BottomPiece-body color-circle-Thread">
                    <div className="nav overflow-x gap-10 mt-auto mb-lg-14 mb-12 align-items-center">
                      {collectionData &&
                        collectionData?.map((value, index) => {
                          return (
                            value.status === 1 && (
                              <OverlayTrigger
                                key={index}
                                delay={{ hide: 100, show: 100 }}
                                overlay={(props) => (
                                  <Tooltip {...props}>{value?.name}</Tooltip>
                                )}
                                placement="bottom"
                              >
                                <Image
                                  className="branding mx-3  h-full"
                                  src={value?.image}
                                  alt={value?.name}
                                  style={{ maxWidth: "160px" }}
                                  onClick={(e) => calulate(e, value)}
                                  draggable="false"
                                />
                              </OverlayTrigger>
                            )
                          );
                        })}
                    </div>
                  </Col>
                </Row>
              )
            )}
            {mainStepIndex === 1 && (
              <div
                className={`left-pattern d-lg-none text-center flex-lg-column rounded-start-3`}
              >
                <span className="text-black patterns-text">
                  Patterns coming soon
                </span>
              </div>
            )}
            {mainStepIndex === 4 && (
              <div className="d-block d-lg-block">
                <InscriptionLogoButtons
                  clearText={clearText}
                  textbox={textbox}
                  inscription={inscription}
                  textChangeHandle={textChangeHandle}
                  textbox2={textbox2}
                />
              </div>
            )}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Footer;
