// Login.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getRedemption } from "../redux/userSessionSlice";

const AlteryxLogin = ({ config }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [inpval, setinpval] = useState({
    redemption: "",
  });
  const [error, setError] = useState();

  const getRedemptionCode = useSelector((state) => state.users.redemption);
  const isLoader = useSelector((state) => state.loader.isLoading);

  const handleChange = (name, value) => {
    setError("");
    setinpval({ ...inpval, [name]: value });
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  // Assuming you have a function to authenticate and get a JWT token
  function generateRandomString(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    return result;
  }
  useEffect(() => {
    if (getRedemptionCode) {
      login();
    }
  }, [getRedemptionCode]);

  const login = () => {
    const redemptionCode = getRedemptionCode?.success;

    if (!redemptionCode) {
      setError(getRedemptionCode?.message);
      return;
    }

    if (redemptionCode) {
      const randomString = generateRandomString(10);
      localStorage.setItem("token", randomString);
      navigate("/");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { redemption } = inpval;

    if (!redemption) {
      return setError("Redemption code is required");
    }

    try {
      await dispatch(getRedemption(redemption));
    } catch (error) {
      console.error("API call failed:", error);
      setError("API call failed");
    }
  };

  return (
    <Container fluid style={{ backgroundColor: "#05233b" }}>
      <div className="min-h-screen justify-content-center vstack">
        <Row>
          <Col lg={6} className="mx-auto">
            <form onSubmit={handleSubmit}>
              <div className="text-center">
                <h3 className="m-5 text-center text-white">
                  Welcome Alteryx Partners!
                </h3>
                <p className="text-center mb-5 text-white">
                  Please enter your redemption code below to start customizing
                  your gift.
                </p>
                <h4 className="text-center text-success "> </h4>
                <div className="mb-3">
                  <input
                    type="text"
                    name="redemption"
                    placeholder="Enter your redemption code..."
                    value={inpval.redemption}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                    className="form-control mb-10 w-sm-1/2 mx-auto"
                    id="exampleI1"
                  />
                  {error && <p className="text-danger">{error}</p>}
                </div>
                <button
                  type="submit"
                  className="btn btn-lg btn-block mb-3 rounded-pill text-white"
                  style={{ backgroundColor: "#006ba5" }}
                  disabled={isLoader}
                >
                  Start Customizing
                </button>
              </div>
            </form>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default AlteryxLogin;
